<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader style="display:flex; justify-content: space-between;">
                <div>
                Usuarios (Usuarios Aplicación)
                <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="createUser(false, $event)"
                >
                  Agregar
                </CButton>
                </div>

                <div style="display:flex; align-items:center;">
                  <CSpinner  color="primary" v-if="loaderSearch" size="sm" class="mb-3 mr-2"/>
                  <font-awesome-icon icon="search" class="mb-3 mr-2" v-else/>
                <CInput
                  v-model="search"
                  placeholder="Buscar usuario"
                />
                </div>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="items"
                  :fields="fields"
                  :items-per-page="pagination.perPage"
                  :pagination="pagination"
                  index-columnS
                >
                <template slot="last_smartband_tests-header">
                  Últimas Mediciones
                  <br>
                  <small>Presión / Frec / Oxi / Temp / Nombre</small>
                </template>

                <template #name="data">
                    <td style="display:flex;">
                        <div v-for="(obj, index) in ids" :key="index">
                          <CButton
                            v-if="data.item.id == obj.id"
                            @click="collapseDeviceData(data.item.id)" 
                            class="mb-2"
                            style="font-size: 8px;"
                          >
                          <font-awesome-icon :icon="ids[index].visible ? 'chevron-up' : 'chevron-down'" />
                          </CButton>
                        </div>
                        <div style="display:flex; flex-direction: column;">
                          <span>{{data.item.name}}</span>
                          <span style="font-size: 12px;">{{data.item.email}}</span>
                        </div>
                    </td>
                </template>
                  <template #device="data">
                    <td v-if="!data.item.last_used_device">Sin Dispositivo Registrado</td>
                    <td v-else>
                      <div>
                        <div v-for="(obj, index) in ids" :key="index">
                        <CCollapse :show="ids[index].visible" v-if="data.item.id == obj.id">
                          <p style="font-size: 12px;" v-if="data.item.last_used_device?.device_so" class="sub-column-title"><span class="sub-column">Sistema Operativo:</span> {{data.item.last_used_device?.device_so}}</p>
                          <p style="font-size: 12px;" v-if="data.item.last_used_device?.device_soversion" class="sub-column-title"><span class="sub-column">Versión S.O.:</span> {{data.item.last_used_device?.device_soversion}}</p>
                          <p style="font-size: 12px;" v-if="data.item.last_used_device?.device_brand" class="sub-column-title"><span class="sub-column">Marca:</span> {{data.item.last_used_device?.device_brand}}</p>
                          <p style="font-size: 12px;" v-if="data.item.last_used_device?.device_name" class="sub-column-title"><span class="sub-column">Nombre:</span> {{data.item.last_used_device?.device_name}}</p>
                        </CCollapse>
                        <p v-if="data.item.id == obj.id && !ids[index].visible">{{data.item.last_used_device?.device_so}} {{data.item.last_used_device?.device_soversion}}.</p>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template #last_smartband_tests="data">
                    <td v-if="!data.item?.last_smartband_tests || !data.item?.last_smartband_tests.length">Sin Registro de mediciones</td>
                    <td v-else>
                      <div v-for="(obj, index) in ids" :key="index">
                      <CCollapse :show="ids[index].visible" v-if="data.item.id == obj.id">
                        <div  style="display:flex; gap:12px; font-size: 12px;">
                        <div v-for="(medicion, i) in data.item.last_smartband_tests" :key="i">
                          <div>
                            <p class="sub-column-title"><span class="sub-column">Presión:</span> {{medicion.sistolica ? medicion.sistolica : '--'}}/{{medicion.diastolica ? medicion.diastolica : '--'}}</p>
                            <p class="sub-column-title"><span class="sub-column">Frecuencia:</span> {{medicion.ritmo ? medicion.ritmo : '--'}}</p>
                            <p class="sub-column-title"><span class="sub-column">Oxígeno:</span> {{medicion.oxigeno ? medicion.oxigeno : '--'}}</p>
                            <p class="sub-column-title"><span class="sub-column">Temperatura:</span> {{medicion.temperatura ? medicion.temperatura : '--'}}</p>
                            <p class="sub-column-title"><span class="sub-column">Auto:</span> {{medicion.auto ? 'Si' : 'No'}}</p>
                            <p class="sub-column-title"><span class="sub-column">Smartband:</span> {{medicion.smartband?.name ? medicion.smartband?.name : '--'}}</p>
                            <p class="sub-column-title"><span class="sub-column">Fecha:</span> {{medicion.smartband?.created_at ? medicion.smartband?.created_at : '--'}}</p>
                          </div>
                      </div>
                        </div>
                      </CCollapse>
                          <div v-for="(medicion, i) in data.item.last_smartband_tests" :key="i">
                        <p v-if="data.item.id == obj.id && !ids[index].visible && i == 0" style="font-weight: 600;">
                          {{medicion.sistolica ? medicion.sistolica : '--'}}/{{medicion.diastolica ? medicion.diastolica : '--'}} | {{medicion.ritmo ? medicion.ritmo : '--'}} | {{medicion.oxigeno ? medicion.oxigeno : '--'}} | {{medicion.temperatura ? medicion.temperatura : '--'}} | {{medicion.smartband?.name ? medicion.smartband.name : '--' }}
                        </p>
                          </div>
                      </div>
                    </td>
                  </template>
                  
                  <template #cuadrante="data" >
                    <td>{{getCuadranteName(data.item.info.cuadrante_id)}}</td>
                  </template>
                  <template #actions="data">
                    <td>

                    <div style="height: 100%; display:flex; align-items:center;">
                      <CButton
                        v-c-tooltip="'Video llamada'"
                        color="success"
                        size="sm"
                        class="m-1"
                        @click="openModalVideoCall(data.item.id)"
                      >
                        <font-awesome-icon icon="phone" />
                      </CButton>
                      <CButton
                        v-c-tooltip="'Ver ficha de usuario'"
                        color="info"
                        size="sm"
                        class="m-1"
                        @click="goToFicha(data.item.id)"
                      >
                        <font-awesome-icon icon="eye" />
                      </CButton>
                    <CButton
                        v-c-tooltip="'Editar datos de usuario'"
                        color="warning"
                        size="sm"
                        class="m-1"
                        @click="openEditModal(data.item, $event)"
                        v-if="checkPermissions(['edit_user_app'])"
                      >
                        <font-awesome-icon icon="user-edit" />
                      </CButton>
                      <CButton
                        v-c-tooltip="'Eliminar usuario'"
                        color="danger"
                        size="sm"
                        class="m-1"
                        @click="deleteUser(data.item.id, $event)"
                        v-if="checkPermissions(['edit_user_app'])"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </CButton>
                    </div>
                    </td>
                  </template>
                </CDataTable>
                <div style="width: 500px; margin: 0 auto;">
              <CPagination
                :active-page.sync="currentPage"
                :pages="Math.ceil(total / pagination.perPage)"
                align="center"
                size="lg"
              />
            </div>
                <div 
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
                  <CSpinner
                    style="width:4rem;height:4rem;"
                    color="danger"
                  />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
<!--           <CCol col="3" xl="3">
            <CCard>
              <CCardHeader>
                Estadísticas
              </CCardHeader>
              <CCardBody>
                <CChartPie
                  v-if="estadisticas && estadisticas.gender && estadisticas.gender.values.length > 0"
                  :datasets="[{
                    backgroundColor: [
                      '#41B883',
                      '#d0282e',
                      '#00D8FF',
                      '#DD1B16'
                    ],
                    data: estadisticas.gender.values
                  }]"
                  :labels="estadisticas.gender.keys"
                />
                <CChartLine
                  class="mt-5"
                  v-if="estadisticas && estadisticas.age && estadisticas.age.values.length > 0"
                  :datasets="[{
                    label: 'Edad',
                    backgroundColor: ['#d0282e'],
                    data: estadisticas.age.values
                  }]"
                  :labels="estadisticas.age.keys"
                  :options="{ 
                    scales: {
                      yAxes: [{
                        ticks: {
                          stepSize: 1
                        }
                      }]
                    }
                  }"
                />
              </CCardBody>
            </CCard>
          </CCol> -->
        </CRow>
      </transition>
    </CCol>
    <EditUser :getUsers="getUsers" :showModal="editModal" :user="updatingUser" :closeModal="() => closeModal()"/>
    <CreateUser :getUsers="getUsers" :showModal="showModal" :closeModal="() => closeModal()"/>
    <ModalVideoCall :user_is_in_attention="user_is_in_attention" :modalVideoCall="modalVideoCall" :close="close" :user_id="user_id" />
  </CRow>
</template>

<script>
import CreateUser from './CreateUser'
import EditUser from './EditUser'
import { getUsers, deleteUser } from '../../api/users'
import { getCuadrantes } from '../../api/cuadrantes'
import checkPermissions from '../../helpers/userPermissions'
import { CChartPie, CChartLine } from '@coreui/vue-chartjs'
import _ from 'lodash/lodash'
import ModalVideoCall from '../atenciones/ModalVideoCall.vue'
import { checkUserInAttention } from '../../api/atenciones'
import moment from "moment";

import * as infermedica from '../../api/infermedica'

export default {
  name: 'Users',
  components: {CreateUser, EditUser, CChartPie, CChartLine, ModalVideoCall},
  data: () => {
    return {
      items: [],
      estadisticas: null,
      fields: [
        { key: 'name', label: 'Nombre' },
        { key: 'device', label: 'Dispositivos' },
        { key: 'last_smartband_tests', label: 'Últimas Mediciones' },
        { key: 'cuadrante', label: 'Cuadrante' },
/*         { key: 'created_at', label: 'Creado' }, */
        { key: 'actions', label: 'Acciones' }
      ],
      currentPage: 1,
      total: null,
      pagination: {
        page: 1,
        perPage: 10,
      },
      updatingUser: null,
      showModal: false,
      loading: true,
      cuadrantes: null,
      search: '',
      loaderSearch: false,
      modalVideoCall: false,
      user_id: null,
      user_is_in_attention: null,
      ids: [],
      windowWidth: window.innerWidth,
      editModal: false
    }
  },
  mounted(){
    this.getUsers()
    this.getCuadrantes()
    window.addEventListener('resize', this.onResize);
    if (!this.$store.state.userData.permisos.permissions.hasOwnProperty('view_user_app')) {
      this.$router.push('/')
    }
  },
  methods: {
    checkPermissions,
      onResize() {
    this.windowWidth = window.innerWidth;
  },
    goToFicha(userID){      
      this.$router.push({ name: 'FichaPaciente', params: {id: userID} })
    },
    collapseDeviceData(id) {
      const index = this.items.findIndex((objeto) => objeto.id === id)
      this.ids[index].visible = !this.ids[index].visible
    },
    getCuadranteName(id){
      if(this.cuadrantes){
        const found = this.cuadrantes.find(function(item){
          return id === item.id
        })
        return found.nombre
      }
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    createUser(user, e){
      e.stopPropagation()
      this.updatingUser = user
      this.showModal = true
    },
    deleteUser(id, e){
      e.stopPropagation()
      const data = {id}
      const self = this
      deleteUser(data)
        .then(function(resp){
          console.log(resp)
          self.getUsers()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getCuadrantes(){
      const data = {
        page: 1,
        limit: 9999999
      }
      const self = this
      getCuadrantes(data)
        .then(function(resp){
          self.cuadrantes = resp.data
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getUsers(){
      const data = {
        limit: this.pagination.perPage,
        page: this.pagination.page,
        search: this.search
      }
      const self = this
      this.loading = false
      getUsers(data)
        .then(function(resp){
          self.ids = []
          self.items = resp.data
          self.items.forEach(item => {
            self.ids.push({id: item.id, visible: false}) 
          })
          console.log(resp)
          self.estadisticas = resp.user_stadistics
          self.loading = false
          self.total = resp.total
          self.loaderSearch = false;
          // resp.data.map(function(item){
          //   self.items.push(item)
          // })s
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    openModalVideoCall(user_id) {
    checkUserInAttention({user_id}).then(res => {
      this.modalVideoCall = true;
      this.user_id = user_id;
      this.user_is_in_attention = res.user_is_in_attention
      if (res.user_is_in_attention) {
        this.getUsers();
      }
    }).catch(e => {
      console.log(e);
    })
    },
    close() {
        this.modalVideoCall = false;
    },
    openEditModal(user) {
      this.updatingUser = user
      this.editModal = true;
    }
  },
    watch: {
    currentPage: _.debounce(function (page) {
      this.pagination = {
        page,
        perPage: this.pagination.perPage,
      };
      this.getUsers(this.pagination)
    }, 500),
    search: _.debounce(function (value) {
      if (this.search.length > 2 ||this.search == '') {
        this.loaderSearch = true;
        this.getUsers();
      }
    }, 1000),
    windowWidth(value) {
      if (value < 1200) {
        this.fields = this.fields.filter(obj => obj.key !== 'cuadrante')
      } else if (value > 1200) {
        if (!this.fields.some(item => item.key === 'cuadrante')) {
          this.fields.splice(this.fields.length - 1, 0, { key: 'cuadrante', label: 'Cuadrante' })
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .sub-column {
    font-weight: 600;
  }
  .sub-column-title {
    margin-bottom: 0;
  }
</style>